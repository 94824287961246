<template>
    <section :id="cmsBlock.anchorTag" class="component-container">
        <div class="text-block" :class="[cmsBlock.textBlockWithButtonWidth]">
            <div class="block-content" :class="cmsBlock.textBlockWithButtonStyle">
                <div v-html="md(localize(cmsBlock.text))" class="cms-content"></div>
                <div class="buttons">
                    <a :href="localize(cmsBlock.buttonLink)"
                        v-if="localize(cmsBlock.buttonText) && !isInternalLink(localize(cmsBlock.buttonLink))"
                        :target="target({ linkType: cmsBlock.linkType })">
                        <button :class="buttonClass">
                            {{ localize(cmsBlock.buttonText) }}
                        </button>
                    </a>
                    <router-link :to="localize(cmsBlock.buttonLink)"
                        v-if="localize(cmsBlock.buttonText) && isInternalLink(localize(cmsBlock.buttonLink))">
                        <button :class="buttonClass">
                            {{ localize(cmsBlock.buttonText) }}
                        </button>
                    </router-link>

                    <template v-for="button in cmsBlock.buttons">
                        <a v-if="!isInternalLink(localizeAlt(button, 'link'))" :href="localizeAlt(button, 'link')"
                            :target="target(button)">
                            <button :class="buttonClass">
                                {{ localizeAlt(button, 'title') }}
                            </button>
                        </a>
                        <router-link v-if="isInternalLink(localizeAlt(button, 'link'))" :to="localizeAlt(button, 'link')"
                            :target="target(button)">
                            <button :class="buttonClass">
                                {{ localizeAlt(button, 'title') }}
                            </button>
                        </router-link>
                    </template>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'TextBlockWithButton',

    props: [
        'cmsBlock'
    ],

    computed: {
        buttonClass() {
            switch (this.cmsBlock.textBlockWithButtonStyle) {
                case 'white':
                    return {
                        'button-dark': true
                    }
                case 'grey':
                    return {
                        'button-dark': true
                    }
                case 'dark':
                    return {
                        'button-light': true
                    }
            }
        }
    },

    methods: {
        target(button) {
            switch (button.linkType) {
                case 'external':
                    return '_blank'
                default:
                    return '_self'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.component-container {
    .text-block {
        width: calc(100% - 2 * getSpacing('padding-default-horizontal', 'desktop'));
        max-width: $container-width;
        margin: 0 auto;
        padding: 0 getSpacing('padding-default-horizontal', 'desktop');

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include breakpoint('tablet') {
            width: calc(100% - 2 * getSpacing('padding-default-horizontal', 'tablet'));
            padding: 0 getSpacing('padding-default-horizontal', 'tablet');
        }

        @include breakpoint('mobile') {
            width: 100%;
            padding: 0 0;
        }

        .block-content {
            width: 100%;

            .cms-content {
                @include breakpoint('mobile') {
                    padding: getSpacing('padding-small-vertical', 'mobile') 0;
                }
            }

            .buttons {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }

            button {
                margin: 10px;
                margin-bottom: 25px;
            }
        }
    }

    .full_width {
        width: 100%;
        max-width: none;
        padding: 0;

        .block-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .cms-content {
            max-width: $container-width;
        }
    }

}
</style>
